<template>
  <transition name="slide-fade">
    <div v-if="showBasket" class="basket" @click.stop.prevent="handleClick">
      <client-only>
        <loading style="z-index:100000" :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
      </client-only>
      <div class="basket__container" @click.stop="doNothing">
        <h2 class="basket__title">Basket</h2>
        <div class="basket__icon" @click.stop.prevent="handleClick">
          <font-awesome-icon :icon="['fal', 'times']" class="fa fa-stack-1x right" />
        </div>
      </div>
      <section class="basket__content" @click.stop="doNothing">
        <div class="basket__panel">
          <transition name="fade">
            <div v-if="!basketNumOfItems" class="basket__empty">
              <p class="basket__empty-heading">Is everything okay?</p>
              <p class="basket__empty-text">Your cart is empty</p>
              <a :href="`${$config.baseURL}/best-selling-magazine-subscriptions`" class="basket__empty-button" target="">Shop Best Selling</a>
            </div>
          </transition>
          <transition-group name="fade">
            <BasketProduct
              v-for="(item, index) in basketItems"
              :key="`${item.offer.productId}-${index}`"
              :price="item.offer.price"
              :qty="item.offer.qty"
              :save="item.offer.save"
              :item="item"
            />
          </transition-group>
        </div>
      </section>

      <BasketTotals v-if="basketNumOfItems" class="basket__bottom" />

      <client-only>
        <div class="basket__content ways_to_pay__container">
          <PDPWaysToPay v-if="basketNumOfItems" />
        </div>
      </client-only>

      <section v-if="basketUpsellItems.length && basketNumOfItems" class="basket__upsell" @click.stop="doNothing">
        <div class="basket__panel">
          <p class="basket__upsell-heading">Why not try one of our other top sellers...</p>
        </div>

        <div>
          <transition-group name="card" class="basket__panel upsell-grid items-list">
            <UpsellProduct
              v-for="item in basketUpsellItems"
              :key="item.productId"
              :type="'upsell'"
              :item="item"
              class="basket__upsell-item items-list__item"
              imgix-query-string="&w=130"
            />
          </transition-group>
        </div>
      </section>
    </div>
  </transition>
</template>

<script>
import differenceWith from 'lodash/differenceWith'
import { mapGetters } from 'vuex'

export default {
  name: 'Basket',
  serverCacheKey() {
    return false
  },
  components: {
    UpsellProduct: () => import('~/components/common/list/Product.vue'),
    BasketProduct: () => import('~/components/common/basket/Product.vue'),
    BasketTotals: () => import('~/components/common/Totals.vue'),
    PDPWaysToPay: () => import('~/components/PDP/PDPWaysToPay.vue')
  },
  props: {
    showBasket: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
    cart: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    basketUpsellItems() {
      // Remove all prods already in the basket
      // const basketItems = this.basket.items || (this.$store.state.basket && this.$store.state.basket.items) || []
      let basketItems = (this.$store.state.basket && this.$store.state.basket.items) || []
      if (this.$nuxt.$config.MongoDBIntegration) {
        basketItems = this.basket.items || []
      }
      // console.log('basketUpsellItems basketItems=', basketItems)
      // console.log('basketUpsellItems upsellItems=', this.upsellItems)
      const newUpsellItems = differenceWith(this.upsellItems, basketItems, function(a, b) {
        return a.offer.productId === b.offer.productId
      })

      // Sort upsell items by position
      newUpsellItems && newUpsellItems.sort((a, b) => b.position - a.position)
      // console.log('basketUpsellItems newUpsellItems=', newUpsellItems)
      // console.log('basketUpsellItems return=', newUpsellItems.slice(0, 4))
      return newUpsellItems.slice(0, 4) || []
    },
    // ...mapState('basket', ['upsellItems']),
    ...mapGetters({
      upsellItems: 'basket/upsell',
      basket: 'basket/payload'
    }),
    basketItems() {
      if (this?.basket?.items && this?.basket?.items[0]?.id === null) {
        console.log('basketItems & BASKET with errors')
        return []
      }
      if (this.$nuxt.$config.MongoDBIntegration) {
        console.log('basketItems MongoDB=', this.basket.items)
        return this.basket.items || []
      } else {
        console.log('basketItems MAGENTO=', this.basket.items)
        return this.basket.items || []
      }
    },
    basketNumOfItems() {
      if (this.$nuxt.$config.MongoDBIntegration) {
        return this.basket.totals.numOfItems
      } else {
        return this.basket.numOfItems
      }
    }
  },
  created() {
    this.$nuxt.$on('SHOW_BASKET_LOADER', value => {
      this.isLoading = value
    })
  },
  methods: {
    handleClick() {
      console.log('Basket handleClick CLOSE')
      if (this.isLoading === false) {
        this.$nuxt.$emit('SHOW_BASKET', false)
      }
    },
    doNothing() {
      console.log('Basket doNothing')
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.card {
  transition: all 0.5s;
}
.card-enter, .card-leave-to
/* .card-leave-active for <2.1.8 */ {
  opacity: 0;
  transform: scale(0);
}
.card-enter-to {
  opacity: 1;
  transform: scale(1);
}

.card-leave-active {
  position: absolute;
}

.card-move {
  opacity: 1;
  transition: all 0.5s;
}

.basket {
  overflow: auto;
  max-height: 100vh;
  position: fixed;
  z-index: 2147483647;
  top: 0;
  left: 0;
  visibility: visible;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;
  transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7);
  &__container {
    z-index: 100;
    transform: none;
    width: 400px;
    height: 65px;
    line-height: 65px;
    border-bottom: 1px solid #000;
    float: right;
    position: relative;
    clear: both;
    background-color: #fff;
    text-align: center;
  }
  &__title {
    float: left;
    padding: 0 20px;
    padding: 0 20px;
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000;
    line-height: unset;
  }
  &__icon {
    float: right;
    vertical-align: middle;
    &:hover {
      cursor: pointer;
    }
    .right {
      box-sizing: content-box;
      position: relative;
      vertical-align: middle;
      padding: 0 20px;
      height: 1.5em;
      width: 1.5em;
    }
  }
  &__content,
  &__bottom,
  &__upsell {
    padding-top: 10px;
    z-index: 100;
    transform: none;
    width: 400px;
    height: auto;
    float: right;
    position: relative;
    clear: both;
    background-color: #fff;
    text-align: center;
  }
  &__empty {
    margin: 40px 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
  &__empty-heading {
    margin: 0 0 10px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 700;
  }
  &__empty-button {
    width: 50%;
    min-width: 300px;
    margin: 10px 4%;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-color: $prodcutOfferColor;
    display: block;
    height: 40px;
    font-size: 13px;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 0;
  }
  &__empty-button:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
    color: #fff;
  }
  &__upsell-heading {
    margin: 0 0 10px;
    letter-spacing: 0.1em;
    font-weight: 700;
    padding: 0 10%;
  }

  .upsell-grid {
    .items-list {
      &__item {
        padding: 0px 20px;
        margin: 10px;
        flex: 1 0 30%;
        max-width: 170px;
        min-width: 100px;
        .actions button {
          background-color: transparent;
          text-align: center;
          font-size: 12px;
          color: $buttonAddToCartColor;
          line-height: 12px;
          margin: 0em;
          text-decoration: underline;
          min-width: 100px;
          padding: 10px 0px;
          &:hover {
            text-decoration: none;
          }
          .spinner {
            top: 40%;
          }
        }
        .view-btn {
          display: none;
        }
        .btn-divider {
          display: none;
        }
      }
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
@media (max-width: $screen-xs-max) {
  .basket {
    overflow: auto;
    max-height: 100vh;

    &__container {
      min-width: 80%;
      max-width: 80%;
    }
    &__content,
    &__bottom,
    &__upsell {
      min-width: 80%;
      max-width: 80%;
    }
    .upsell-grid {
      .items-list {
        &__item {
          margin: 10px;
          flex: 1 0 30%;
          max-width: 170px;
          min-width: 100px;
          padding: unset;
          img {
            max-width: 100px;
          }
        }
      }
      margin-bottom: 40px;
    }

    &__empty-button {
      width: 40%;
      min-width: 200px;
    }
  }
  .basket__icon .right {
    box-sizing: content-box;
    padding: 0 20px;
  }
  .basket__bottom {
    padding-bottom: 0 !important;
  }
}
@media (max-width: 320px) {
  .basket {
    overflow: auto;
    max-height: 100vh;

    &__container {
      min-width: 100%;
      max-width: 100%;
      max-width: 320px;
    }
    &__content,
    &__bottom,
    &__upsell {
      min-width: 100%;
      max-width: 100%;
      max-width: 320px;
    }
    .upsell-grid {
      .items-list {
        &__item {
          flex: 0 0 30%;
        }
      }
      margin-bottom: 40px;
    }

    &__empty-button {
      width: 40%;
      min-width: 200px;
    }
  }
  .basket__icon .right {
    box-sizing: content-box;
    padding: 0 20px;
  }
}

/* ==========================================================================
    Internet Explorer Section
   ========================================================================== */
/*  IE10+ */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .upsell-grid {
    .items-list {
      justify-content: space-around;
    }
  }
}
.ways_to_pay__container {
  display: block;
  z-index: 100;
  float: right;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 0 !important;
}

/* iPhone 14 Pro Max - 1284×2778 pixels at 460ppi */
@media only screen and (max-device-width: 430px) and (max-device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
  .basket {
    .items-list__item {
      min-height: 265px !important;
    }
  }
}
</style>
