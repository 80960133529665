<template>
  <div class="header">
    <div class="header__desktop">
      <div class="header--logo">
        <div class="header--bars">
          <LazyHydrate when-visible>
            <div>
              <font-awesome-icon v-if="!isHomePage" :icon="['fal', 'bars']" class="fa fa-2x bars" @click="handleNavClick" />
            </div>
          </LazyHydrate>
        </div>

        <a href="/">
          <img :src="`${$config.imgixURL}/wysiwyg/logo_home_desktop_transparent.png?auto=format&w=700&drp=3`" alt="magazine.co.uk" width="350" height="45" loading="lazy" />
        </a>
      </div>
      <div class="header--side">
        <div class="header--tagline">The UK's Leading Magazine Subscription Site</div>
        <div class="header--cart">
          <div data-target-element="#header-cart" class="header--cart__desktop" @click="handleBasketClick">
            <LazyHydrate when-visible>
              <span :data-count="numOfItems" class="fa-stack fa-1x has-badge">
                <font-awesome-icon :icon="['fal', 'shopping-basket']" class="fa shopping-cart fa-stack-2x red-cart" />
              </span>
            </LazyHydrate>
            <span class="header--cart-label">Your basket</span>
          </div>
        </div>
      </div>
    </div>
    <div class="header__mobile">
      <div class="header--side">
        <div class="header--menu" @click="handleNavClick">
          <span class="fa-stack fa-1x">
            <font-awesome-icon :icon="['fal', 'bars']" class="fa fa-stack-2x white" />
          </span>
        </div>
        <div class="header--logo">
          <a href="/">
            <img :src="`${$config.imgixURL}/wysiwyg/logo_home_mobile.png?auto=format&w=348&h=42`" alt="magazine.co.uk" width="174" height="21" loading="lazy" />
          </a>
        </div>
        <div class="header--cart" @click="handleBasketClick">
          <LazyHydrate when-visible>
            <span :data-count="numOfItems" class="fa-stack fa-1x">
              <font-awesome-icon :icon="['fal', 'shopping-basket']" class="fa fa-stack-2x white" />
            </span>
          </LazyHydrate>
        </div>
      </div>
    </div>

    <Nav :show-nav="showNav" />
    <Notification :show-notification="showNotification" :notification="getNotification" />
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'Head',
  serverCacheKey: props => `Head::isHomePage${props.isHomePage}::${props.timestamp}`,
  components: {
    LazyHydrate,
    Nav: () => import('~/components/common/Nav.vue'),
    Notification: () => import('~/components/common/Notification.vue')
  },
  props: {
    isHomePage: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    timestamp: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showNav: false,
      showNotification: false,
      notification: {},
      numOfItems: 0
    }
  },

  computed: {
    getNotification() {
      return this.notification
    }
  },
  mounted() {
    this.$fb && this.$fb.enable()
    // Dispatch a Cart Update on every reload of the page
    if (this.$nuxt.$config.MongoDBIntegration) {
      // TODO MONGODB INTEGRATION
    }
    if (this.$store.state.basket.id) {
      // console.log('basket.id=', this.$store.state.basket.id)
      this.$store.dispatch('LOAD_ACTIVE_BASKET', { cartId: this.$store.state.basket.id, context: this })
    }
  },
  created() {
    this.$nuxt.$on('SHOW_NAV', value => {
      this.showNav = value
      this.showNotification = false
    })

    this.$nuxt.$on('SHOW_NOTIFICATION', notification => {
      // Centralise Error messaging
      if (notification.show === false) {
        this.showNotification = false
      } else {
        this.showNotification = true
        this.notification = notification.data ? notification.data : { code: 'DEFAULT', message: 'Ooooohps An error occurred, please try later' }
        setTimeout(() => (this.showNotification = false), 4000)
      }
    })

    this.$nuxt.$on('BASKET_READY', ({ ready, basket }) => {
      console.info(`Event BASKET_READY for Head ready=${ready} basket=`, basket)
      if (basket) {
        if (this.$nuxt.$config.MongoDBIntegration) {
          console.info(`Event BASKET_READY MONGODB`)
          this.numOfItems = undefined
          this.numOfItems = basket.num_of_items
        } else {
          console.info(`Event BASKET_READY M1`)
          this.numOfItems = 0
          this.numOfItems = basket?.numOfItems || 0
        }
      }
    })
  },
  methods: {
    handleBasketClick() {
      this.$nuxt.$emit('SHOW_BASKET', true)
    },
    handleNavClick() {
      this.showNav = true
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  // XMAS HEADER
  background-image: url(https://magazine.imgix.net//wysiwyg/XMAS_2022.png?auto=format&dpr=3&w=1230);
  background-repeat: no-repeat;
  background-size: 1230px;
  background-position-y: -20px;

  flex-flow: row wrap;
  display: flex;
  justify-content: center;
  max-width: 1230px;
  width: 100%;
  height: 80px;
  &__mobile,
  .bars {
    content-visibility: auto;
    display: none;
  }
  .header__tablet-bars {
    display: block;
  }
  &__desktop {
    content-visibility: auto;
    padding: 0;
    flex: 0 1 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
    height: 80px;
    // background-image: url('/images/JMAG_Xmas_homepage_2020.png');
    // background-repeat: no-repeat;
    // background-position: center center;
  }
  &--logo {
    padding-left: 20px;
    a img {
      max-width: 360px;
    }
  }

  &--side {
    flex: 0 1 40%;
    align-self: flex-end;
    min-height: 10px;

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    padding-right: 20px;
  }
  &--tagline {
    min-height: 40px;
    font-size: 14px;
    font-style: italic;
    color: #ff0d00;
  }

  &--cart {
    vertical-align: middle;
    $shopping-cart-red: #df0000;
    &:hover {
      cursor: pointer;
    }

    .fa-stack[data-count]:after {
      position: absolute;
      right: 0%;
      top: 0%;
      content: attr(data-count);
      font-size: 9px;
      padding: 0.2em;
      border-radius: 999px;
      border-color: #df0000;
      line-height: 0.75em;
      color: white;
      // color: $shopping-cart-red;
      text-align: center;
      min-width: 1em;
      font-weight: bold;
      background: #df0000;
      border-style: solid;
    }
    .fa-circle {
      color: #df0000;
    }

    .red-cart {
      color: black;
      background: white;
    }

    div {
      font-size: 14px;
      font-weight: 700;
      color: #333;
      text-decoration: none;

      &:hover,
      &:active {
        text-transform: none;
      }
      display: block;
      span {
        vertical-align: bottom;
      }
    }
    &__desktop {
      width: 122.55px;
      height: 28px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .fa-1x {
    font-size: 0.9em;
  }
  .header {
    max-height: 55px;
    &__desktop {
      content-visibility: auto;
      display: none;
    }
    &__mobile {
      content-visibility: auto;
      min-width: 100%;
      min-height: 50px;
      max-height: 55px;
      padding: 0px;
      background-color: #333;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      background-image: none;
    }
    &--logo {
      padding-left: 10px;
      a img {
        width: 100%;
        height: 100%;
        display: block;
        max-width: 174px;
      }
    }
    &--side {
      flex: 0 1 100%;
      min-height: 50px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding-right: 0px;
    }

    &--cart,
    &--menu {
      // border-left: 1px solid #666;
      padding: 7px;
      .white {
        color: white;
      }
      width: 50px;
      height: 46.8px;
    }
  }
}
@media (max-width: $screen-sm-min) {
  .header__desktop {
    min-height: 80px;
    .header--logo {
      img {
        max-width: 240px;
        height: 34px;
      }
    }
    .header--tagline {
      font-size: 13px;
    }
  }
  .header--logo {
    display: flex;
    align-items: center;
  }
}
@media screen and (width: $screen-sm-min) {
  .header {
    background-position: 5%;
    height: 110px;
  }
}
@media screen and (max-width: 1190px) and (min-width: 767px) {
  .header__desktop {
    .header--logo {
      background-color: white;
      opacity: 0.9;
      max-width: 370px;
      display: flex;
      align-items: center;
      .header__tablet-bars {
        display: block;
        margin-right: 20px;
      }
      img {
        max-width: 350px;
        height: 45px;
      }
    }
  }
}

.header--bars {
  content-visibility: none;
  display: none;
}

/* ----------- iPad and iPad Mini  ----------- */
/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .header--bars {
    content-visibility: visible;
    display: block;
    .bars {
      display: block;
      margin-right: 20px;
    }
  }
}
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .header {
    height: 100px !important;
  }
  .header--bars {
    content-visibility: visible;
    display: block;
    .bars {
      display: block;
      margin-right: 20px;
    }
  }
}
</style>
