<template>
  <div class="giftfinder__main">
    <h1 style="display: none">Gift Finder</h1>
    <div v-if="dataUI.categories" class="giftfinder--bg">
      <div class="giftfinder">
        <div class="giftfinder__img">
          <a href="/gift_finder">
            <img src="https://magazine.imgix.net/wysiwyg/gift-logo.png" />
          </a>
        </div>
        <div v-if="!params.id" class="giftfinder__head-text">
          Fear not, we are here to help. Tell us a little more and we'll get to work on finding you the perfect magazine gift.
        </div>
        <div v-if="dataUI.categories" class="giftfinder__container">
          <div class="giftfinder__container">
            <div class="giftfinder__label">
              <span>{{ dataUI.labelName }}</span>
            </div>
            <div class="giftfinder__suggestions">
              <div
                v-for="(cat, index) in dataUI.categories"
                :key="index"
                :class="{ 'giftfinder__suggestion--twocolumns': params.id }"
                class="giftfinder__suggestion"
                @click.prevent="handleClick(cat)"
              >
                <span>{{ cat.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!params.id" class="giftfinder__bottom-text">
      <p>
        Our easy-to-use gift finder helps you find the perfect magazine gift for all your loved ones, whatever their passion. Get started now by telling us a little more about them
        and we'll get to work on finding you a gift that's sure to surprise and delight.
      </p>
    </div>
    <div v-else class="giftfinder__bottom-text--empty" :class="{ 'display-none': dataUI.products }"></div>
    <div v-if="dataUI.products" class="giftfinder-results">
      <div class="giftfinder-results__img">
        <a href="/gift_finder">
          <img src="https://magazine.imgix.net/wysiwyg/gift-logo.png" />
        </a>
      </div>
      <div class="giftfinder-results__title">
        <span>I need a gift for....</span>
        <span class="giftfinder-results__title--bold"> {{ dataUI.parentLabelName.split('...')[1] }}</span>
      </div>
      <LazyHydrate when-visible>
        <CLPProductsList v-if="dataUI.productsWithOffer && dataUI.productsWithOffer.length" :values="dataUI.productsWithOffer" />
      </LazyHydrate>
    </div>
  </div>
</template>
<script>
import LazyHydrate from 'vue-lazy-hydration'
import { getBreadcrumbsMicrodataJSONLD } from '~/lib/util/microdata'
import { azureGiftFinderSearch, mapAzuresearchResultsToProduct } from '~/lib/util/azuresearch'

export default {
  name: 'GiftFinder',
  components: {
    LazyHydrate,
    CLPProductsList: () => import('~/components/giftfinder/GiftFinderProductsList.vue')
  },

  layout: '1Column',
  fetchOnServer: true,

  fetch() {
    // Breadcrumbs
    const breadcrumbs = [
      {
        first: true,
        value: 'magazine subscriptions',
        url: '/',
        last: false
      },
      {
        first: false,
        value: 'GiftFinder',
        url: '/gift_finder',
        last: true
      }
    ]
    this.breadcrumbs = breadcrumbs
    this.$store.commit('ADD_BREADCRUMBS', { path: this.$route.path, breadcrumbs })
  },

  async asyncData({ store, route, params, query, $ua, $axios, error, redirect }) {
    if ((!params.id && params.islast) || (params.id && !params.islast)) {
      redirect('/gift_finder')
    }

    // Look for an available Query String and retrieve the URL path
    const path = route.path
    const useCache = store.getters['UI/useCache']
    console.log('page=Giftfinder')
    console.log('path=', path)
    console.log('query=', query)
    console.log('params=', params)
    console.log('QS useCache=', useCache)

    require('axios-debug-log')
    // Calling the Frontend Api for the Page (we have axios-cache LRU in place)
    // Default GET params
    let axiorParams = {
      siteId: 1
    }

    // Click on a Gift Finter Category
    let url = 'https://giftfinderapi.magazinecloner.com/api/getCategories'
    if (params.id) {
      axiorParams.parentId = params.id
    }

    // Click on the Last Category -> Show the products
    if (parseInt(params.islast) === 1) {
      url = 'https://giftfinderapi.magazinecloner.com/Products/getProducts'
      axiorParams = {
        siteId: 1,
        categoryId: params.id
      }
    }

    // Retrieve the Gift Finder Results for the Page
    const response = await $axios
      .get(url, {
        params: axiorParams,
        useCache
      })
      .then(response => {
        return response?.data || {}
      })
      .catch(e => {
        error({ statusCode: 500, message: 'An error occurred' })
      })

    console.log('Azure Gift Finder API response=', response)

    // Get all the Products Data from Azure Search
    if (parseInt(params.islast) === 1) {
      await azureGiftFinderSearch({ products: response.products }, (error, data) => {
        if (data) {
          // console.log('data=', data.value)
          response.productsWithOffer = data.value.map(product => {
            return mapAzuresearchResultsToProduct(product)
          })
          console.log(`COUNT=${data['@odata.count']}`)
        } else if (error) {
          console.log(error)
        }
      })
    }

    return {
      isFromMobile: $ua.isFromIphone() || $ua.isFromAndroidMobile(),
      dataUI: response,
      params
    }
  },
  data() {
    return {
      dataUI: {},
      breadcrumbs: [],
      canonicalURL: 'https://www.magazine.co.uk/gift_finder',
      h1Title: 'Gift Finder',
      metaDescription: 'Our easy-to-use gift finder helps you find the perfect magazine gift for all your loved ones - give it a try now!',
      metaKeywords: ''
    }
  },
  methods: {
    handleClick({ id, isLastLayer }) {
      // Click on a Category
      window.location.href = `${this.$config.baseURL}/gift_finder/${id}/${+isLastLayer}`
    }
  },

  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To check how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    return {
      title: this.metaTitle,
      link: [{ rel: 'canonical', href: this.canonicalURL }],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.metaDescription
        },
        {
          name: 'keywords',
          content: this.metaKeywords || ''
        },
        {
          name: 'robots',
          content: this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this.h1Title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.metaDescription
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: 'https://www.magazine.co.uk/icon.png'
        }
      ]
    }
  },
  jsonld() {
    return getBreadcrumbsMicrodataJSONLD({ breadcrumbs: this.breadcrumbs })
  }
}
</script>
<style lang="scss" scoped>
.giftfinder__main {
  flex: 1 1 100%;
}
.giftfinder--bg {
  background-image: url('https://magazine.imgix.net/wysiwyg/gift_finder_xmas2020.png?fm=png&auto=format&compress&w=1100');
  // background-image: url('https://magazine.imgix.net/wysiwyg/default-giftfinder-bg-web.png?fm=png&auto=format&compress&w=1100');
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 450px;
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
}

.giftfinder-results {
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  max-width: 790px;
  justify-content: flex-start;
  align-items: center;

  &__img {
    flex: 0 1 100%;
    img {
      display: block;
      width: 100%;
    }
    max-width: 180px;
  }
  &__title {
    flex: 0 1 100%;
    -ms-flex: 0 1 100%;
    font-size: 25px;
    margin: 20px auto;
    &--bold {
      color: $headingColor;
    }
  }
  &__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    align-content: space-around;
    width: 100%;
  }
}

.giftfinder {
  //display: none;
  @include border-radius(5px);
  position: relative;
  background-color: white;
  font-size: 14px;
  margin: 20px auto;
  padding: 5px 30px;

  max-width: 350px;
  min-height: 420px;

  display: flex;
  flex-flow: row wrap;
  width: 50%;
  justify-content: center;
  align-items: center;

  &__head-text {
    padding: 10px;
    font-size: 10px;
    text-align: center;
  }
  &__bottom-text {
    text-align: center;
    max-width: 500px;
    display: block;
    margin: 0px auto;
    padding: 10px;
    p {
      white-space: pre-line;
      font-size: 10px;
    }
    &--empty {
      height: 40px;
    }
  }

  .giftfinder__img {
    img {
      display: block;
      margin: 0 auto;
      width: 90%;
    }
    max-width: 330px;
    flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    align-items: center;
    border-bottom: 1px solid #a9a9a9;
  }

  .giftfinder__container > div {
    flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    min-width: 100%;
  }

  .giftfinder__container {
    min-width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    .giftfinder__label {
      flex: 1 1 100%;
      -ms-flex: 1 1 100%;
      line-height: 50px;
      text-align: center;
      span {
        font-weight: 700;
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
      }
    }
    .giftfinder__suggestions {
      display: flex;
      flex-flow: row wrap;
      padding-bottom: 10px;

      .giftfinder__suggestion {
        @include border-radius(5px);
        flex: 1 1 100%;
        -ms-flex: 1 1 100%;
        margin: 10px;
        line-height: 50px;
        text-align: center;
        border: 1px solid #a9a9a9;
        span {
          padding: 5px;
          display: inline-block;
          vertical-align: middle;
          line-height: normal;
        }
        &:hover {
          cursor: pointer;
          background-color: #ff0d00;
          color: white;
        }

        &--twocolumns {
          flex: 0 1 43%;
          -ms-flex: 0 1 41%;
        }

        &--clicked {
          pointer-events: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .giftfinder__main {
    padding: 0 15px;
  }
  .giftfinder {
    border: 1px solid #a9a9a9;
    width: 100%;
    max-width: 350px;

    margin: 15px auto;
    padding: 5px;
    &__bottom-text {
      p {
        white-space: normal;
        font-size: 10px;
      }
    }
  }

  .giftfinder-results {
    &__img {
      max-width: 180px;
    }
    &__title {
      font-size: 20px;
    }
  }
}

@media (max-width: 480px) {
  .giftfinder--bg {
    background: none;
  }
  .giftfinder {
    border: none;
    width: 100%;
    max-width: 350px;

    margin: 10px auto;
  }

  .giftfinder-results {
    &__img {
      max-width: 130px;
    }
    &__title {
      font-size: 18px;
    }
  }
}
</style>
