<template>
  <section class="container" @click.stop="doNothing">
    <client-only v-if="$store.getters['UI/debug']">
      <ul class="debug__list">
        <li class="debug__item">
          <tree-view :data="getTotals" :options="{ rootObjectKey: 'Totals', maxDepth: 0, link: true }"> </tree-view>
        </li>
      </ul>
    </client-only>
    <div class="container__totals">
      <div v-if="getSave > 0" class="savings__circle">
        <div class="savings__circle--label">YOU SAVE</div>
        <div class="savings__circle--save">{{ getSaveWithCurrency }}</div>
      </div>
      <div>
        <div v-if="getDiscountAmount" class="container__discount my-2">
          Discount ({{ getDiscountDescription }}) <span class="container__total--red">-{{ getDiscountAmount }}</span>
        </div>
        <div class="container__total my-2">
          Total <span class="container__total--red">{{ getGrandTotalsWithCurrency }}</span>
        </div>
      </div>
    </div>
    <div class="container__footer">
      <a :href="`${$config.baseURL}/`" title="Continue shopping" class="container__footer--continue-shopping"><span>Continue Shopping</span></a>
      <a title="Checkout Now" class="container__footer--checkout" @click="handleCheckoutNow"><span>Checkout Now</span></a>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { formatCurrency } from '~/lib/util/basket'

export default {
  data() {
    return {}
  },
  computed: mapState({
    getGrandTotalsWithCurrency: state => (state.basket && state.basket.totals && formatCurrency(state.basket.totals.grandTotal)) || undefined,
    getDiscountAmount: state => (state.basket && state.basket.discount && state.basket.discount.amount && formatCurrency(state.basket.discount.amount)) || undefined,
    getDiscountDescription: state => (state.basket && state.basket.discount && state.basket.discount.description) || undefined,
    getSaveWithCurrency: state => (state.basket && state.basket.totals && formatCurrency(state.basket.totals.save)) || 0,
    getSave: state => (state.basket && state.basket.totals && state.basket.totals.save) || 0,
    getTotals: state => (state.basket && state.basket.totals) || {}
  }),

  methods: {
    doNothing() {
      console.log('Totals doNothing')
    },
    async handleCheckoutNow() {
      await this.$nuxt.$emit('SHOW_BASKET_LOADER', true)
      window.location.href = `${this.$config.backendURL}/checkout/cart/`
      setTimeout(() => this.$nuxt.$emit('SHOW_BASKET_LOADER', false), 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
.debug__list {
  width: 90% !important;
  margin: 5%;
}
.debug__list .debug__item .tree-view-wrapper {
  text-align: left !important;
}
.container {
  border: none;
  min-height: 30px;
  margin: 0;
  padding: 10px 0 25px 0;
  &__totals {
    padding-right: 20px;
    padding-bottom: 20px;
    float: right;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
  }
  &__total {
    align-self: center;
    text-align: right;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    &--red {
      color: $totalsCurrencyColor;
    }
  }
  &__discount {
    align-self: center;
    text-align: right;
    font-size: 14px;
    font-weight: 700;
    text-transform: none;
    &--red {
      color: $totalsCurrencyColor;
    }
  }
  &__footer {
    text-align: right;
    padding: 0px 20px;
    margin: 0;
    clear: both;
    a {
      display: inline-block;
      vertical-align: middle;
      -webkit-transition-property: all;
      transition-property: all;
      -webkit-transition-duration: 0.1s;
      transition-duration: 0.1s;
      -webkit-transition-timing-function: linear;
      transition-timing-function: linear;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      border: none;
      background: $mobileButtonBgColor;
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      padding: 10px;
      text-transform: uppercase;
      text-align: center;
      &:hover {
        opacity: 0.7;
        filter: alpha(opacity=70);
      }
    }

    &--continue-shopping {
      float: left;
      background: $prodcutOfferColor !important;
      padding-left: 20px;
    }
    &--checkout {
      background: $buttonBgColorAction !important;
      float: right;
      padding-right: 20px;
    }
  }
}
.savings {
  position: relative;

  &__circle {
    margin-right: 20px;
    vertical-align: middle;
    background: white;
    border-radius: 100%;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
    color: #ff0d00;
    display: inline-block;
    height: 87px;
    text-align: center;
    width: 87px;

    border: 3px solid #ff0d00;
    height: 70px;
    width: 70px;

    &--label {
      font-size: 11px;
      font-weight: 700;
      line-height: 1;
      margin-top: 18px;
    }

    &--save {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.3;
    }
  }
}
@media (max-width: $screen-xs-max) {
  .container {
    &__footer {
      padding: 10px;
      width: 100%;
      height: 100%;
      min-height: 50px;
      display: flex;
      flex-flow: column-reverse;
      align-items: center;
      &--continue-shopping {
        color: $buttonBgColorBack !important;
        margin: 10px;
        float: none;
        width: 70%;
        background: white !important;
        text-transform: none !important;
        text-decoration: underline !important;
      }
      &--checkout {
        margin: 10px;
        width: 70%;
        background: $mobileButtonBgColor !important;
        float: none;
      }
    }
    &__discount {
      font-size: 12px;
    }
    .savings__circle {
      margin-right: 5px;
      height: 65px;
      width: 65px;
      &--label {
        font-size: 10px;
      }
      &--save {
        font-size: 14px;
      }
    }
  }
}
</style>
